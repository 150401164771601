import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo/Logo'
import './Header.scss'

export default function Header(props) {
    return (
        <header>
            <Link to='/'>
                <Logo />
            </Link>
        </header>
    )
}
