import React, { useContext } from 'react'
import './AlbumTitle.scss'
import { Link, NavLink } from 'react-router-dom'

export default function AlbumTitle(props) {

    const [hover, setHover] = React.useState(false)
    const [mousePosition, setMousePosition] = React.useState({
        x: 0,
        y: 0,
    })
    const handleMouseEnter = (e) => {
        setHover(true)
        setMousePosition({
            x: e.pageX + 25,
            y: e.pageY
        })
    }

    const handleMouseMove = (e) => {
        setMousePosition({
            x: e.pageX + 25,
            y: e.pageY
        })
    }

    const handleMouseLeave = (e) => {
        setHover(false)
    }

    return (

            <div className="album">
                <Link to={`albums/${props.name}`}>
                    <p onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} style={{textAlign: 'center'}}>{props.name}</p>
                </Link>
                <div
                    className='mouse-photo'
                    style={{
                        left: mousePosition.x,
                        top: mousePosition.y,

                        backgroundImage: `url(${props.backgroundImage})`,

                        transform: `translate3d(0%, -50%, 0) rotate(${props.rotate}deg)`,


                        opacity: hover ? '1' : '0'
                    }}
                >
                    
                        <div className='caption'>
                            <p>{props.name}</p>
                        </div>
                </div>
            </div>
    )
}
