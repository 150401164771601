import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import React from 'react'

const animations = {
    initial: {opacity: 0, y: 100},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0, y: 100},
}

export default function PhotoViewAnimation({children}) {

    return (
            <motion.div
                key="modal"
                variants={animations}
                initial="initial"
                animate="animate"
                exit="exit"
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    display: 'flex'
                }}
                transition= {{duration: 0.2}}
            >
                {children}
            </motion.div>
    )
}
