import React from 'react'
import './Logo.scss'

export default function Logo(props) {
    return (
        <div id="top_logo">
           <h1>STUDIO PANAME</h1>
           <h2>Portfolio</h2> 
        </div>
    )
}
