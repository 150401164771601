import React from 'react'
import { Link } from 'react-router-dom'
import './FavSlider.scss'

export default function FavSlider(props) {

    console.log(process.env.PUBLIC_URL);

    return (
        <div className="fav_slider_container">
            <h2>{props.title}</h2>
            <div className="fav_assets_container">
                {
                    props.assets.map((asset, index) => {

                        return (
                            <Link key={index} to={`albums/${asset.album}/${parseInt(asset.photo, 10) +1}`}>
                                <div className="fav_asset">
                                    <img className="fav_cover_photo" src={process.env.PUBLIC_URL + `/assets/min_photos/${asset.album}/${asset.album}_${asset.photo}.jpg`} alt={`Photo représentant l'album ${asset.name}`}/>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}
