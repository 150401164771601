import React from 'react'
import './Favorites.scss'

export default function Favorites(props) {
    return (
        <div>
            
        </div>
    )
}
