import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import PhotoViewAnimation from '../../layout/Animations/PhotoViewAnimation'
import './Photo.scss'

export default function Photo(props) {

    let params = useParams()
    let album = props.assets.find(asset => asset.name === params.albumName)

    const initialPhotoDelta = {
        x: 0,
        y: 0,
        opacity: 1,
        transition: 'opacity 0.5s ease-in 0.2s' 
    }
    const [photoDelta, setPhotoDelta] = useState(initialPhotoDelta)
    
    const PrevHandler = () => {
        props.setSelectedIndex((prevState) => {
            if (prevState === 0) {
                return album.last
            } else {
                return prevState - 1
            }
        })
    } 
    const NextHandler = () => {
        props.setSelectedIndex((prevState) => {
            if (prevState === album.last) {
                return 0
            } else {
                return prevState + 1
            }
        })
    }
    const QuitHandler = () => {
        props.setSelectedIndex(null)
        props.setPhotoView(false)
    }
    const ArrowHandler = (e) => {
        console.log(e.key)
        switch (e.key) {
            case 'ArrowLeft':
                PrevHandler()
                break;
            case 'ArrowRight':
                NextHandler()
                break;
            case ' ':
                NextHandler()
                break;

            case 'Escape':
                QuitHandler()
        
            default:
                break;
        }
    }

    const handlers = useSwipeable({
        onSwipedLeft: (e) => {
            setTimeout(() => {
                NextHandler()
                setPhotoDelta(initialPhotoDelta)
            }, e.velocity*150)
        },
        onSwipedRight: (e) => {
            setTimeout(() => {
                PrevHandler()
                setPhotoDelta(initialPhotoDelta)
            }, e.velocity*150)
        },
        onSwipedUp: (e) => {
            setTimeout(() => {
                setPhotoDelta(initialPhotoDelta)
            }, e.velocity*150)
        },
        onSwipedDown: (e) => {
                QuitHandler()
        },
        preventScrollOnSwipe: true,
        onSwiping: (e) => {
            setPhotoDelta({
                ...photoDelta,
                x: e.deltaX,
                y: e.deltaY,
                opacity: 0.9,
            })
        },
        onSwiped: (e) => {
            setPhotoDelta(prev => ({
                x: prev.x + e.vxvy[0] * 100,
                y: prev.y + e.vxvy[1] * 100,
                opacity: 0,
                transition: `all ${e.velocity/10}s ease-out`
            }))
        }
    })


    useEffect(() => {
        window.addEventListener('keydown', ArrowHandler)
        window.history.replaceState(null, document.title, `/albums/${album.name}/${parseInt(props.selectedIndex, 10) +1}`)
        return () => {
            window.removeEventListener('keydown', ArrowHandler)
            window.history.replaceState(null, document.title, `/albums/${album.name}`)    
        }
    }, [props.selectedIndex])

    return (
        <PhotoViewAnimation>
            <div className="photo_modal"
                style={{
                    width: props.appsize.width,
                    height: props.appsize.height,
                    flexFlow: props.appsize.landscape ? 'row' : 'column',
                    justifyContent: props.appsize.landscape ? 'space-evenly' : 'center',
                    transition: 'opacity 0.2s ease',
                }}
            >
                <div className="close_btn_container"
                    onClick={QuitHandler}
                    style={{
                        height: props.appsize.landscape ? '100%' : '',
                        width: props.appsize.landscape ? '' : '100%',
                        alignItems: props.appsize.landscape ? 'start' : 'center',
                        paddingLeft: props.appsize.landscape ? '' : '25px',
                        paddingTop: props.appsize.landscape ? '25px' : ''
                    }}
                >
                        <div className="close_btn">
                            <div></div>
                            <div></div>
                        </div>
                </div>
                <img
                    { ...handlers }
                    src={process.env.PUBLIC_URL + `/assets/photos/${album.name}/${album.name}_${props.selectedIndex}.jpg`}
                    alt={`Photo à l'index ${props.selectedIndex} de l'album ${album.name}.`}
                    style={{
                        objectFit: props.appsize.landscape ? 'contain' : 'cover',
                        height: props.appsize.landscape ? '100%' : '',
                        maxHeight: props.appsize.landscape ? '' : props.appsize.height - 100,
                        flexGrow: props.appsize.landscape ? '' : '1',
                        width: props.appsize.landscape ? 'auto' : '100%',
                        //Swipe transform
                        transform: `translate3d(${photoDelta.x}px, ${photoDelta.y}px, 0)`,
                        opacity: photoDelta.opacity,
                        transition: photoDelta.transition
                    }}
                />
                <div className="photo_infos">
            
                    <div className="pagination">
                        <div className="prev_btn" onClick={PrevHandler}></div>
                        <p>{`${parseInt(props.selectedIndex, 10) +1}/${album.last +1}`}</p>
                        <div className="next_btn" onClick={NextHandler}></div>
                    </div>
                </div>
            </div>
        </PhotoViewAnimation>
    )
}
