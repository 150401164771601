import React from 'react'
import { Link } from 'react-router-dom'
import LeftAppear from '../../layout/Animations/LeftAppear'
import './Albums.scss'

export default function Albums(props) {
    return (
        <>
            <LeftAppear>
                <main className="albums-container">
                    <div className="title-container">
                        <Link className="btn-back" to={'/'} />
                        <h1>Mes albums</h1>
                    </div>
                    <div className="albums">
                        {
                                props.assets.map((asset) => {

                                    return (
                                    <Link to={asset.name} key={asset.name}>
                                        <div className="asset">
                                            <img className="cover_photo" src={process.env.PUBLIC_URL + `/assets/photos/${asset.name}/${asset.name}_0.jpg`} alt={`Représentation (premier cliché) de l'album ${asset.name}`}/>
                                            <strong><p>{asset.name}</p></strong>
                                            <p>{asset.last +1}</p>
                                        </div>
                                    </Link>
                                    )
                                })
                            }
                    </div>
                </main>    
            </LeftAppear>
            
        </>
    )
}
