import React from "react";
import { Route, Routes, useLocation } from "react-router";
import "./App.scss";
import Home from "./pages/Home/Home";
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
import _ from "lodash";
import Albums from "./pages/Albums/Albums";
import Album from "./pages/Album/Album";
import Favorites from "./pages/Favorites/Favorites.jsx";
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();

  const assets = [
    {
      id: 0,
      name: "Nature",
      last: 14,
    },
    {
      id: 1,
      name: "Paris",
      last: 5,
    },
    {
      id: 2,
      name: "Portraits",
      last: 14,
    },
  ];

  const [appsize, setAppsize] = React.useState({
    width: window.visualViewport.width,
    height: window.visualViewport.height,

    mobile: window.visualViewport.width <= 768 ? true : false,
    landscape:
      window.visualViewport.width > window.visualViewport.height ? true : false,
  });

  const resizeHandler = (e) => {
    let newVal = {
      width: window.visualViewport.width,
      height: window.visualViewport.height,
      mobile: window.visualViewport.width <= 768 ? true : false,
      landscape:
        window.visualViewport.width > window.visualViewport.height
          ? true
          : false,
    };
    updateAppSize(newVal);
  };

  const updateAppSize = _.debounce((val) => {
    setAppsize(val);
  }, 100);

  React.useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    window.addEventListener("orientationchange", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("orientationchange", resizeHandler);
    };
  });

  return (
    <div
      className="App"
      style={{
        maxWidth: appsize.width,
        minHeight: appsize.height,
      }}
    >
      <img
        id="Vector_Tower"
        src="/assets/svg/Eiffel_Tower.svg"
        alt="Illustration vectorielle représentant la Tour Eiffel"
      />
      <Header />

      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route
            path="/"
            element={<Home appsize={appsize} assets={assets} />}
          />
          <Route path="about" element={<Home appsize={appsize} />} />
          <Route
            path="albums"
            element={<Albums appsize={appsize} assets={assets} />}
          />
          <Route
            path="albums/:albumName"
            element={<Album assets={assets} appsize={appsize} />}
          />
          <Route
            path="albums/:albumName/:asset"
            element={<Album assets={assets} appsize={appsize} />}
          />

          <Route path="favorites" element={<Favorites appsize={appsize} />} />
          <Route
            path="*"
            element={
              <h2>Il n'y a rien à voir ici ! Veuillez vérifier l'URL saisie</h2>
            }
          />
        </Routes>
      </AnimatePresence>

      <Footer />
    </div>
  );
}

export default App;
