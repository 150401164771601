import React from 'react'
import { Link } from 'react-router-dom'
import './ImgSlider.scss'
import AlbumTitle from '../AlbumTitle/AlbumTitle'

export default function ImgSlider(props) {

    return (
        <div className="slider_container">
            <Link to={'albums'}>
                <h2>{props.title}</h2>
            </Link>
            {
            //CHECK APPSIZE
            props.appsize.mobile ?
                //MOVILE VERSION
                <>
                    <div className="assets_container">
                        {
                            props.assets.map((asset) => {

                                return (
                                    <Link key={asset.id} to={`albums/${asset.name}`}>
                                        <div className="asset">
                                            <img className="cover_photo" src={`/assets/min_photos/${asset.name}/${asset.name}_0.jpg`} alt={`Photo représentant l'album ${asset.name}`}/>
                                            <strong><p>{asset.name}</p></strong>
                                            <p>{asset.last +1}</p>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </>
                // OR
                :
                //DESKTOP VERSION
                <>
                    <div className="assets_container_desktop">
                        {
                            props.assets.map((asset) => {

                                let rotate = Math.random()*10
                                console.log(rotate)

                                return (
                                    
                                        <AlbumTitle 
                                            name={asset.name}
                                            backgroundImage={`/assets/min_photos/${asset.name}/${asset.name}_0.jpg`}
                                            rotate = {rotate}
                                        />
                                )
                            })
                        }
                    </div>
                </>
            }
            
        </div>
    )
}
