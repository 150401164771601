import React from 'react'
import './Footer.scss'

export default function Footer() {

    let date = new Date()


    return (
        <footer>Une création Eliot Nedelec © {date.getFullYear()}</footer>
    )
}
