import React from "react";
import ImgSlider from "../../components/ImgSlider/ImgSlider";
import FavSlider from "../../components/FavSlider/FavSlider";
import "./Home.scss";
import LeftAppear from "../../layout/Animations/LeftAppear";

export default function Home(props) {
  return (
    <>
      <LeftAppear>
        <main className="home_container">
          <p>
            <q>Human after all</q>
          </p>

          <div className="galleries_container">
            <FavSlider
              title={"Coups de cœur ♥️"}
              assets={[
                {
                  album: "Nature",
                  photo: 4,
                },
                {
                  album: "Nature",
                  photo: 1,
                },
                {
                  album: "Portraits",
                  photo: 3,
                },
                {
                  album: "Portraits",
                  photo: 7,
                },
                {
                  album: "Portraits",
                  photo: 12,
                },
                {
                  album: "Paris",
                  photo: 2,
                },
                {
                  album: "Portraits",
                  photo: 10,
                },
                {
                  album: "Paris",
                  photo: 1,
                },
              ]}
              appsize={props.appsize}
            />
            <ImgSlider
              title={"Albums 📕"}
              assets={props.assets}
              appsize={props.appsize}
            />
          </div>

          <p>Contactez nous :</p>

          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img id="insta_logo" src="/assets/svg/Instagram.svg" alt="" />
          </a>
        </main>
      </LeftAppear>
    </>
  );
}
