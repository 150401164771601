import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'

import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import LeftAppear from '../../layout/Animations/LeftAppear'
import Photo from '../Photo/Photo'
import './Album.scss'

export default function Album(props) {

    let params = useParams()
    let album = props.assets.find(asset => asset.name === params.albumName)
    let asset = params.asset
    let arrayAlbum = [...Array(album.last +1)]

    const [photoView, setPhotoView] = useState(asset ? true : false)
    const [selectedIndex, setSelectedIndex] = useState(asset ? asset-1 : 0)
    
    //DOM JSX
    return (
        <>
        <LeftAppear>
            <main className='album_container'>
                <div className='album_cover'>
                        <Link className="btn-back" to={'/albums'} />
                    <h2>{album.name}</h2>
                    <p>{album.last + 1} photo{album.last +1 == 1 ? '' : '(s)'}</p>
                </div>
                <div className='photos_container'>
                    {arrayAlbum.map((el, index) => {

                        let onClickHandler = () => {
                            setPhotoView(true)
                            setSelectedIndex(index)
                        }

                        return(
                                <div onClick={onClickHandler}>
                                    <img className='album_photo' src={process.env.PUBLIC_URL + `/assets/min_photos/${album.name}/${album.name}_${index}.jpg`} alt={`Photo à l'index ${index} de l'album ${album.name}.`} />
                                </div>
                        )
                    })}

                </div>
                <AnimatePresence>
                {
                    photoView &&
                        <Photo assets={props.assets} appsize={props.appsize} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} setPhotoView={setPhotoView} key="photo"/>
                }
                </AnimatePresence>
            </main>
        </LeftAppear>
        </>
    )
}
