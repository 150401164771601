import { motion, useAnimation } from 'framer-motion'
import React from 'react'

const animations = {
    initial: {opacity: 0, x: -100},
    animate: {opacity: 1, x: 0},
    exit: {opacity: 0, x: -100},
}

export default function LeftAppear({children}) {

    return (
        <motion.div 
            variants={animations}
            initial="initial"
            animate="animate"
            exit="exit"
            style={{
                width: '100%',
                height: '100%',
                display: 'flex'
            }}
            transition= {{duration: 0.2}}
        >
            {children}
        </motion.div>
    )
}
